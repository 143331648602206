/* Instead google font css link */
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTk3iazbXWjgevT5.woff)
    format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTA3iazbXWjgevT5.woff)
    format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTs3iazbXWjgevT5.woff)
    format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTo3iazbXWjgevT5.woff)
    format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTQ3iazbXWjgeg.woff)
    format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html.dark {
  background-color: rgb(15, 23, 42);
}
body {
  font-family: 'Nunito', sans-serif;
}
.cell-empty {
  background-color: #e7eff6;
}
.cell-correct {
  background: linear-gradient(180deg, #19a48b 0%, #05b494 100%);
  box-shadow: 0px 2px 3px 0px rgb(1 32 26 / 18%), inset 0 -2px 2px 0 #04806a,
    inset 0 2px 2px #05ba9a;
}
.cell-absent {
  background-color: #3c3f59;
  color: #a3a3ae;
}
.cell-present {
  /* box-shadow: 1.0077093798760471e-15px 5.142857074737549px 10.285714149475098px 0px #2D22043D; */
  box-shadow: 0px 2px 3px 0px rgba(45, 34, 4, 0.24), inset 0 -2px 2px 0 #ca8704,
    inset 0 2px 2px #ffc41a;
}
img.del-btn {
  width: 32px;
  height: 32px;
}
.key-default {
  background: linear-gradient(180deg, #6d6d6d 0%, #525252 100%);
}
.key-absent {
  background-color: #3c3f59;
  color: #a3a3ae;
}
.key-correct {
  background: linear-gradient(180deg, #19a48b 0%, #05b494 100%);
  box-shadow: inset 0 -2px 2px 0 #04806a, inset 0 2px 2px #05ba9a;
}
.key-present {
  box-shadow: inset 0 -2px 2px 0 #ca8704, inset 0 2px 2px #ffc41a;
}
.bottom-banner {
  margin: 5px auto;
  max-width: 30rem;
  min-height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.z-9999 {
  z-index: 9999;
}
.stat-share-btn {
  background-color: #19a48b;
  width: auto;
  min-width: 80%;
}
